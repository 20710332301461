import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { HiX } from "react-icons/hi";
import {
  MdOutlineRouter,
  MdOutlineDashboard,
  MdLogout,
  MdOutlinePeopleAlt,
  MdOutlineTextSnippet,
  MdOutlineConnectWithoutContact,
  MdOutlineEngineering,
  MdOutlineSimCard,
  MdOutlineLocalShipping,
  MdOutlineShoppingBag,
} from "react-icons/md";
import { RiCouponLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import { UserLogout } from "../../api/logout";

export default function SideBar({ MenuState, onMenuState }) {
  const history = useHistory();
  const [Pathname, setPathname] = useState("");

  useEffect(() => {
    let isMounted = true;
    let pathname = history.location.pathname.split("/")[1];

    if (isMounted) setPathname(pathname);

    return () => {
      isMounted = false;
    };
  }, [history.location.pathname]);

  function handlePathnameChange(pathname) {
    setPathname(pathname);
    handleCloseMenu();
  }

  function handleCloseMenu() {
    onMenuState(false);
  }

  function handleOnLogout() {
    UserLogout();
  }

  return (
    <div id="sidebar" data-active={MenuState}>
      <div className="top">
        <img src="/logo.png" alt="Logo" className="logo" />

        <p className="title">Admin Panel</p>

        <div id="close-icon" data-active={MenuState} onClick={handleCloseMenu}>
          <IconButton color="inherit" aria-label="Close">
            <HiX className="icon" />
          </IconButton>
        </div>
      </div>

      <div className="content">
        <div className="body">
          <Link to="/">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("")}
              data-active={Pathname === ""}
            >
              <MdOutlineDashboard className="link-icon" />
              <p className="link-text">Overview</p>
            </div>
          </Link>
          <Link to="/devices">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("devices")}
              data-active={Pathname === "devices"}
            >
              <MdOutlineRouter className="link-icon" />
              <p className="link-text">Devices</p>
            </div>
          </Link>
          <Link to="/users">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("users")}
              data-active={Pathname === "users"}
            >
              <MdOutlinePeopleAlt className="link-icon" />
              <p className="link-text">Users</p>
            </div>
          </Link>
          <Link to="/leads">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("leads")}
              data-active={Pathname === "leads"}
            >
              <MdOutlineTextSnippet className="link-icon" />
              <p className="link-text">Leads</p>
            </div>
          </Link>
          <Link to="/demorequests">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("demorequests")}
              data-active={Pathname === "demorequests"}
            >
              <MdOutlineConnectWithoutContact className="link-icon" />
              <p className="link-text">Demo Requests</p>
            </div>
          </Link>
          <Link to="/blues">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("blues")}
              data-active={Pathname === "blues"}
            >
              <RiCouponLine className="link-icon" />
              <p className="link-text">Blues.io</p>
            </div>
          </Link>
          <Link to="/members">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("members")}
              data-active={Pathname === "members"}
            >
              <MdOutlineEngineering className="link-icon" />
              <p className="link-text">Members</p>
            </div>
          </Link>
          <Link to="/shop">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("shop")}
              data-active={Pathname === "shop"}
            >
              <MdOutlineShoppingBag className="link-icon" />
              <p className="link-text">Shop</p>
            </div>
          </Link>
          <Link to="/cellular-orders">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("cellular-orders")}
              data-active={Pathname === "cellular-orders"}
            >
              <MdOutlineLocalShipping className="link-icon" />
              <p className="link-text">Cellular Orders</p>
            </div>
          </Link>
          <Link to="/cellulars">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("cellulars")}
              data-active={Pathname === "cellulars"}
            >
              <MdOutlineSimCard className="link-icon" />
              <p className="link-text">Cellulars</p>
            </div>
          </Link>
          <Link to="/coupons">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("coupons")}
              data-active={Pathname === "coupons"}
            >
              <RiCouponLine className="link-icon" />
              <p className="link-text">Coupons</p>
            </div>
          </Link>

          <Link to="/license">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("license")}
              data-active={Pathname === "license"}
            >
              <MdOutlineRouter className="link-icon" />
              <p className="link-text">License</p>
            </div>
          </Link>

          {/* <Link to="/generate-license">
            <div
              className="link-item"
              onClick={() => handlePathnameChange("generate-license")}
              data-active={Pathname === "generate-license"}
            >
              <MdOutlineRouter className="link-icon" />
              <p className="link-text">Generate License</p>
            </div>
          </Link> */}

        </div>

        <div className="bottom">
          <IconButton
            color="inherit"
            aria-label="Logout"
            onClick={handleOnLogout}
          >
            <MdLogout className="logout-icon" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
